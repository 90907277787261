<template>
  <div id="app" @click="handleClick">
    <CarouselSlide :imageSrc="slides[currentSlideIndex].imageSrc" :text="slides[currentSlideIndex].text"
      :players="slides[currentSlideIndex].players" :date="slides[currentSlideIndex].date"
      :score="slides[currentSlideIndex].score" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import CarouselSlide from "./components/CarouselSlide.vue";

const slides = [
  {
    imageSrc: "images/image1.jpg",
    score: "10-0",
    text: "congrats, you suck!",
    players: "Jannis+Sascha - Rouven+Sandro",
    date: "14.09.2023",
  },
  {
    imageSrc: "images/image2.jpg",
    score: "10-0",
    text: "Get some skill...",
    players: "Adi+Raphi - Rouven+Meloni",
    date: "21.09.2023",
  },
  {
    imageSrc: "images/image3.jpg",
    score: "10-0",
    text: "Here we go again...",
    players: "Fauchi+Adi - Rouven+Meloni",
    date: "26.09.2023",
  },
  {
    imageSrc: "images/image4.jpg",
    score: "10-0",
    text: "Easy win",
    players: "Sandro+Raphi - Roli+Rene",
    date: "29.09.2023",
  },
  {
    imageSrc: "images/image5.jpg",
    score: "7-0",
    text: "Schwiz vs Република Србија",
    players: "Adi+Sandro - Andjela+Marko",
    date: "04.10.2023",
  },
  {
    imageSrc: "images/image6.jpg",
    score: "10-0",
    text: "Don't be mad, be better!",
    players: "Rouven+Adi - Andjela+Sandro",
    date: "05.10.2023",
  },
  {
    imageSrc: "images/image7.jpg",
    score: "10-0",
    text: "After Ferie Warmup!",
    players: "Jannis+Sezgin - Meloni+Buchsi",
    date: "16.10.2023",
  },
  {
    imageSrc: "images/image8.jpg",
    score: "7-0",
    text: "Mimimimimi",
    players: "Jannis+Buchsi - Andjela+Leon",
    date: "17.10.2023",
  },
  {
    imageSrc: "images/image9.jpg",
    score: "7-0",
    text: "Übung macht den Meister.",
    players: "Jannis+Sezgin - Meloni+Rouven",
    date: "18.10.2023",
  },
  {
    imageSrc: "images/image10.jpg",
    score: "7-0",
    text: "you'll never learn...",
    players: "Fauchi+Sandro - Meloni+Rouven",
    date: "13.10.2023",
  },
  {
    imageSrc: "images/image11.jpg",
    score: "10-0",
    text: "free solo",
    players: "Jannis - Rouven",
    date: "18.10.2023",
  },
  {
    imageSrc: "images/image12.jpg",
    score: "10-0",
    text: "lockeres aufwärmen",
    players: "Jannis+Adrian Manfred - Sandro+Meloni",
    date: "23.10.2023",
  },
  {
    imageSrc: "images/image13.jpg",
    score: "5-0",
    text: "weak ass",
    players: "Andjiiii - Meloni",
    date: "30.10.2023",
  },
  {
    imageSrc: "images/image15.jpg",
    score: "10-0",
    text: "Sträng di doch ah mann!",
    players: "Adi+Sascha - Rene+Rouven",
    date: "31.10.2023",
  },
  {
    imageSrc: "images/image14.jpg",
    score: "7-0",
    text: "Es chönds halt ned all glich guet...",
    players: "René+Andjii - Patrick+Meloni",
    date: "03.11.2023",
  },
  {
    imageSrc: "images/image16.jpg",
    score: "10-0",
    text: "Here we go again, again...",
    players: "Adi+Buchsi - Meloni+Sandro",
    date: "02.02.2024",
  },
  {
    imageSrc: "images/image17.jpg",
    score: "10-0",
    text: "1v2! 1v2 ihr bots!",
    players: "Raphi - Rouven+Meloni",
    date: "07.02.2024",
  },
  {
    imageSrc: "images/image18.jpg",
    score: "10-0",
    text: "Azra, lueg mol LMS vom Adi...",
    players: "Raphi+Rouven - Azra+Meloni",
    date: "20.02.2024",
  },
  {
    imageSrc: "images/image19.jpg",
    score: "10-0",
    text: "Welcome to the team!",
    players: "Raphi+??? - Rouven+Dennis",
    date: "04.04.2024",
  },
  {
    imageSrc: "images/image20.jpg",
    score: "10-0",
    text: "Welcome to the team... Again!",
    players: "Raphi+Jannis - Meloni+Dennis",
    date: "30.04.2024",
  },
  {
    imageSrc: "images/image21.jpg",
    score: "7-0",
    text: "Got em goooooood!",
    players: "Sara+Meloni - Rouven+Nando",
    date: "01.05.2024",
  },
  {
    imageSrc: "images/image22.jpg",
    score: "10-0",
    text: "Welcome to the frame!",
    players: "Jannis - Sara",
    date: "06.05.2024",
  },
  {
    imageSrc: "images/image23.jpg",
    score: "10-0",
    text: "Youngster vs. oldies.",
    players: "Sascha+Roli - Rouven+Dennis",
    date: "06.05.2024",
  },
  {
    imageSrc: "images/image24.jpg",
    score: "7-0",
    text: "Expect the unexpected!",
    players: "Rouven+Raphi - Adi+Sascha",
    date: "12.07.2024",
  },
  {
    imageSrc: "images/image25.jpg",
    score: "10-0",
    text: "Shame on you!",
    players: "Sascha+Jannis - Sara+Buchsi",
    date: "13.08.2024",
  }
];

const currentSlideIndex = ref(0);
let cycleTimeout = null;

const startCycle = () => {
  cycleTimeout = setTimeout(() => {
    currentSlideIndex.value = (currentSlideIndex.value + 1) % slides.length;
    startCycle();
  }, 15000);
};

const stopCycle = () => {
  if (cycleTimeout) {
    clearTimeout(cycleTimeout);
    cycleTimeout = null;
  }
};

const handleClick = (event) => {
  stopCycle();
  const x = event.clientX;
  const width = window.innerWidth;
  if (x < width / 2) {
    // Clicked on the left half of the screen, go back
    currentSlideIndex.value = (currentSlideIndex.value - 1 + slides.length) % slides.length;
  } else {
    // Clicked on the right half of the screen, go forward
    currentSlideIndex.value = (currentSlideIndex.value + 1) % slides.length;
  }
  startCycle();
};

startCycle();
</script>
