<template>
    <div class="carousel-slide">
        <img :src="imageSrc" class="filtered-image" />
        <!-- <div class="title">
            <p>frame of shame</p>
        </div> -->
        <div class="footer">
            <div class="text">
                <p>{{ text }}</p>
            </div>
            <div class="date">
                <p>{{ players }}</p>
                <p>{{ date }}</p>
            </div>
        </div>
        <div class="score">
            <p>{{ score }}</p>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        imageSrc: String,
        score: String,
        text: String,
        players: String,
        date: String,
    },
};
</script>
  
<style scoped>
body,
html {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.carousel-slide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-slide img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center center;
}

.filtered-image {
    filter: brightness(1.1) contrast(1.2) grayscale(0.2) sepia(0.2);
}

/* .title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #4ccd4f;
    font-family: 'Rubik', sans-serif;
}

.title p {
    margin: 0;
    font-size: 36px;
    font-weight: 500;
} */

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.text {
    color: #343a40;
    font-family: 'Rubik', sans-serif;
}

.text p {
    margin: 0;
    font-size: 28px;
    font-weight: 400;
}

.date {
    color: #343a40;
    font-family: 'Rubik', sans-serif;
}

.date p {
    margin: 0;
    font-size: 28px;
    text-align: right;
    font-weight: 400;
}

.score {
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #4ccd4f;
    padding: 10px;
    font-family: 'Rubik', sans-serif;
}

.score p {
    margin: 0;
    font-size: 128px;
    font-weight: 700;
}
</style>

  